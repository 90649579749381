(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/components/loader/assets/javascripts/facade.js') >= 0) return;  svs.modules.push('/components/components/loader/assets/javascripts/facade.js');

svs = svs || {};
var oldLoader;

svs.components = svs.components || {};
svs.components.Loader = svs.ui.Loader;

if (svs.components.loader && svs.components.loader.templates) {
  oldLoader = svs.components.loader;
}

svs.components.loader = new svs.ui.Loader();

if (oldLoader) {
  svs.components.loader.templates = oldLoader.templates;
}

svs.events.subscribe('/loader/show', function() {
  svs.components.loader.show();
});

svs.events.subscribe('/loader/hide', function() {
  svs.components.loader.hide();
});


 })(window);